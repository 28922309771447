import bgImage1080 from '@assets/background/wallpaper_1080.jpg';
import bgImage720 from '@assets/background/wallpaper_720.jpg';
import bgImageSmall from '@assets/background/wallpaper_small.jpg';
import { LoginItem } from '@components/LoginItem';
import { useDocumentTitle } from '@hooks/useDocumentTitle';
import { useScreenSizeBreakpoint } from '@hooks/useScreenSizeBreakpoints';
import { Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  ForeFrontLogo,
  LoginContainer,
  LoginDesc,
  LoginWrapper,
} from './styles';

export function LoginPage() {
  useDocumentTitle('Logga in');

  const theme = useTheme();
  const [source, setSource] = useState(bgImageSmall);
  const [isLoaded, setIsLoaded] = useState(false);
  const largeScreen = useScreenSizeBreakpoint('lg');

  useEffect(() => {
    // Lazy loading background image based on screensize (not responsive)
    const img = new Image();
    img.src = largeScreen ? bgImage1080 : bgImage720;
    img.onload = () => {
      setSource(largeScreen ? bgImage1080 : bgImage720);
      setIsLoaded(true);
    };
  }, [largeScreen]);

  return (
    <LoginWrapper theme={theme} bg={source} isLoaded={isLoaded}>
      <ForeFrontLogo
        src="/assets/forefront-logo.svg"
        alt="Forefront Consulting logotyp"
        loading="lazy"
        width="165"
      />
      <LoginContainer>
        <Typography
          component={'h1'}
          mb={1}
          fontSize={theme.typography.pxToRem(25)}
          fontWeight={500}
        >
          Logga in
        </Typography>
        <LoginDesc mb={4}>
          Välkommen till <b>Track</b>, för att logga in använder du ditt
          Forefront-konto.
        </LoginDesc>
        <LoginItem />
      </LoginContainer>
    </LoginWrapper>
  );
}

export default LoginPage;
