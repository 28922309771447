import { useActiveUser } from '@context/ActiveUserContext';
import { UserRole } from '@enums/Enums';
import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

interface RoleProps {
  children: ReactNode;
  roles: UserRole[];
  path?: string;
}

// prettier-ignore
export const ALL_ROLES = [UserRole.Admin, UserRole.User, UserRole.Dev, UserRole.None];
export const ADMIN_ROLE = [UserRole.Admin, UserRole.Dev];
export const USER_ROLE = [UserRole.User, UserRole.Dev];
export const DEV_ROLE = [UserRole.Dev];
export const NO_ROLE = [UserRole.None];

export default function Roles({ roles, children, path }: RoleProps) {
  const { activeUser } = useActiveUser();

  if (path) {
    if (path === '/') {
      if (activeUser?.activeRole && activeUser.activeRole === UserRole.Admin) {
        return <Navigate to="/admin" replace={true} />;
      }
    }
    if (path === '/admin') {
      if (activeUser?.activeRole && activeUser.activeRole === UserRole.User) {
        return <Navigate to="/" replace={true} />;
      }
    }
  }

  if (activeUser?.activeRole && roles.includes(activeUser.activeRole)) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return null;
}
