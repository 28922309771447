export enum UserRole {
  User = 'User',
  Admin = 'Admin',
  Dev = 'Developer',
  None = 'None',
}

export enum ContractType {
  Commitment = 'Åtagande',
  Expert = 'Expert',
  Onboarding = 'Nyanställd',
}

export enum Status {
  NotHandled = 'NOT_HANDLED',
  Handled = 'HANDLED',
  ReceivedOffer = 'RECEIVED_OFFER',
}

export enum ExtentType {
  Percent = 'PERCENT',
  Hourly = 'HOURLY',
}
