import {
  Configuration,
  RedirectRequest,
  SilentRequest,
} from '@azure/msal-browser';
import { environment } from '@environments/environment';

const clientId = process.env['NX_AZURE_CLIENT_ID'];
const tenantId = process.env['NX_AZURE_TENANT_ID'];

if (!clientId && environment.production) {
  throw new Error('Missing NX_AZURE_CLIENT_ID environment variable');
}

if (!tenantId && environment.production) {
  throw new Error('Missing NX_AZURE_TENANT_ID environment variable');
}

export const msalConfig: Configuration = {
  auth: {
    navigateToLoginRequestUrl: true,
    clientId: clientId || '',
    authority: `https://login.microsoftonline.com/${tenantId}`,
    redirectUri: '/',
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

const requestScopes = [`${msalConfig.auth.clientId}/Access`];

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest: RedirectRequest = {
  scopes: requestScopes,
  redirectStartPage: '/',
};

export const silentRequest: SilentRequest = {
  scopes: requestScopes,
};
