import { selectedUserRoleKey } from '@constants/constants';
import { UserRole } from '@enums/Enums';

export const switchUserRole = (role: UserRole) => {
  window.localStorage.setItem(selectedUserRoleKey, role);
  window.location.reload();
};

export const getSavedUserRole = (): UserRole | null => {
  const userRole = window.localStorage.getItem(selectedUserRoleKey);
  return userRole ? (userRole as UserRole) : null;
};
