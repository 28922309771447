import { useMsal } from '@azure/msal-react';
import { MaterialSymbol } from '@components/MaterialSymbol';
import { loginRequest } from '@config/authConfig';
import { StyledButton } from './styles';

export const LoginItem: React.FC = () => {
  const { instance } = useMsal();
  const handleLogin = () => {
    instance.loginRedirect(loginRequest);
  };

  return (
    <StyledButton
      variant="contained"
      onClick={handleLogin}
      endIcon={<MaterialSymbol size={24} icon="chevron_right" fill={true} />}
    >
      Logga in
    </StyledButton>
  );
};

export default LoginItem;
