import { Button, darken, styled } from '@mui/material';

export const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.contrastText,
  borderRadius: theme.shape.borderRadius * 1.5,
  padding: '5px 17px',
  boxShadow: 'none',
  letterSpacing: '1px',
  borderWidth: '1px',
  borderStyle: 'solid',
  fontSize: '1rem',
  fontWeight: 600,
  textTransform: 'uppercase',
  '&:hover': {
    backgroundColor: darken(theme.palette.primary.contrastText, 0.1),
    boxShadow: 'none',
  },
}));
