import { ContractType, ExtentType, Status } from '@enums/Enums';
import { EmployeeSummaryResponseInterface } from '@interfaces/EmployeeSummaryResponseInterface';

export const employeeSummaryResponse: EmployeeSummaryResponseInterface = {
  pageIndex: 0,
  totalPageCount: 1,
  totalRecordCount: 1,
  pageRecordCount: 1,
  records: [
    {
      customerId: 2,
      customerName: 'Ikano Bank',
      id: 1,
      employeeId: 1,
      employeeEmail: 'ninni.granlund@forefront.se',
      employeeFirstName: 'Ninni',
      employeeLastName: 'Granlund',
      role: 'Arkitekt',
      extent: 50,
      extentType: ExtentType.Percent,
      monthsWorked: 12,
      monthsLeftOfAssignment: 3,
      status: Status.NotHandled,
      lastDateOfAssignment: '2023-09-15',
      assignments: [
        {
          id: 1,
          projectId: 1234567,
          projectName: 'Project name',
          // startDate: '2022-01-01',
          endDate: '2023-05-01',
          role: 'Utvecklare',
          extent: 100,
          extentType: ExtentType.Percent,
          contractType: ContractType.Commitment,
          source: '',
        },
        {
          id: 2,
          projectId: 1234568,
          projectName: 'Project name 2',
          startDate: '2022-02-02',
          endDate: '2023-02-02',
          role: 'Utvecklare',
          extent: 100,
          extentType: ExtentType.Percent,
          contractType: ContractType.Expert,
          source: '',
        },
        {
          id: 3,
          projectId: 1234569,
          projectName: 'Project name',
          startDate: '2022-10-01',
          endDate: '2023-04-30',
          role: 'Utvecklare',
          extent: 100,
          extentType: ExtentType.Percent,
          contractType: ContractType.Commitment,
          source: '',
        },
        {
          id: 4,
          projectId: 1234570,
          projectName: 'Project name 2',
          startDate: '2023-01-01',
          endDate: '2023-06-15',
          role: 'Utvecklare',
          extent: 100,
          extentType: ExtentType.Percent,
          contractType: ContractType.Expert,
          source: '',
        },
      ],
    },
    {
      customerId: 12,
      customerName: 'Riksbyggen',
      id: 2,
      employeeId: 2,
      employeeEmail: 'frans.gundestad@forefront.se',
      employeeFirstName: 'Frans',
      employeeLastName: 'Gundestad',
      role: 'Systemutvecklare',
      extent: 100,
      extentType: ExtentType.Percent,
      monthsWorked: 16,
      monthsLeftOfAssignment: 8,
      status: Status.Handled,
      lastDateOfAssignment: '2023-12-15',
      statusUpdatedAt: '2024-01-10',
      comment:
        'Lorem ipsum dolor sit amet. lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet. lorem ipsum dolor sit amet.',
      assignments: [
        {
          id: 1,
          projectId: 158862,
          projectName: 'Frontend dev',
          startDate: '2022-09-01',
          endDate: '2023-12-15',
          role: 'Utvecklare',
          extent: 100,
          extentType: ExtentType.Percent,
          contractType: ContractType.Commitment,
          source: '',
        },
      ],
    },
    {
      customerId: 2,
      customerName: 'Ikano Bank',
      id: 3,
      employeeId: 3,
      employeeEmail: 'filippa.ryngelsson@forefront.se',
      employeeFirstName: 'Filippa',
      employeeLastName: 'Bryngelsson',
      role: 'Utvecklare',
      extent: 100,
      extentType: ExtentType.Percent,
      monthsWorked: 10,
      monthsLeftOfAssignment: 12,
      status: Status.NotHandled,
      lastDateOfAssignment: '2024-01-15',
      assignments: null,
    },
    {
      customerId: 3,
      customerName: 'Swedbank',
      id: 4,
      employeeId: 4,
      employeeEmail: 'hanna.karlsson@forefront.se',
      employeeFirstName: 'Hanna',
      employeeLastName: 'Karlsson',
      role: 'Utvecklare',
      extent: 100,
      extentType: ExtentType.Percent,
      monthsWorked: 20,
      monthsLeftOfAssignment: 9,
      status: Status.NotHandled,
      lastDateOfAssignment: '2023-09-30',
      assignments: null,
    },
    {
      customerId: 3,
      customerName: 'Swedbank',
      id: 5,
      employeeId: 5,
      employeeEmail: 'fia.berg@forefront.se',
      employeeFirstName: 'Fia',
      employeeLastName: 'Berg',
      role: 'Produktägare',
      extent: 100,
      extentType: ExtentType.Percent,
      monthsWorked: 16,
      monthsLeftOfAssignment: 11,
      lastDateOfAssignment: '2024-12-30',
      status: Status.NotHandled,
      assignments: [
        {
          id: 1708,
          projectId: 272713,
          projectName: 'Förlängning Fullstackutvecklare',
          role: 'Förlängning Fullstackutvecklare',
          extent: 100,
          extentType: ExtentType.Percent,
          startDate: '2024-01-01',
          endDate: '2024-12-31',
          contractType: ContractType.Expert,
          source: 'Cinode',
        },
        {
          id: 924,
          projectId: 234709,
          projectName: 'Utveckling',
          role: 'Utveckling',
          extent: 100,
          extentType: ExtentType.Percent,
          startDate: '2023-01-01',
          endDate: '2023-12-31',
          contractType: ContractType.Expert,
          source: 'Cinode',
        },
        {
          id: 923,
          projectId: 147846,
          projectName: 'Fullstack-utveckling',
          role: 'Fullstack-utveckling',
          extent: 100,
          extentType: ExtentType.Percent,
          startDate: '2022-03-31',
          endDate: '2022-12-31',
          contractType: ContractType.Expert,
          source: 'Cinode',
        },
      ],
    },
    {
      customerId: 1,
      customerName: 'Ericsson',
      id: 6,
      employeeId: 6,
      employeeEmail: 'johan.svensson@forefront.se',
      employeeFirstName: 'Johan',
      employeeLastName: 'Svensson',
      role: 'Utvecklare',
      extent: 100,
      extentType: ExtentType.Percent,
      monthsWorked: 22,
      monthsLeftOfAssignment: 12,
      status: Status.NotHandled,
      lastDateOfAssignment: '2023-12-15',
      assignments: null,
    },
    {
      customerId: 2,
      customerName: 'Ikano Bank',
      id: 7,
      employeeId: 7,
      employeeEmail: 'gustav.skoglund@forefront.se',
      employeeFirstName: 'Gustav',
      employeeLastName: 'Skoglund',
      role: 'Scrum master',
      extent: 50,
      extentType: ExtentType.Percent,
      monthsWorked: 24,
      monthsLeftOfAssignment: 2,
      status: Status.ReceivedOffer,
      lastDateOfAssignment: '2023-09-18',
      assignments: [
        {
          id: 1,
          projectId: 1234567,
          projectName: 'Project name',
          startDate: '2022-01-01',
          endDate: '2023-05-01',
          role: 'Utvecklare',
          extent: 100,
          extentType: ExtentType.Percent,
          contractType: ContractType.Commitment,
          source: '',
        },
        {
          id: 2,
          projectId: 1234568,
          projectName: 'Project name 2',
          startDate: '2022-02-02',
          endDate: '2023-02-02',
          role: 'Utvecklare',
          extent: 100,
          extentType: ExtentType.Percent,
          contractType: ContractType.Expert,
          source: '',
        },
      ],
    },
    {
      customerId: 10,
      customerName: 'Testkund',
      id: 8,
      employeeId: 8,
      employeeEmail: 'test@example.com',
      employeeFirstName: 'Bob',
      employeeLastName: 'Test',
      role: 'Scrum master',
      extent: 50,
      extentType: ExtentType.Percent,
      monthsWorked: 17,
      monthsLeftOfAssignment: 2,
      status: Status.NotHandled,
      lastDateOfAssignment: '2023-09-18',
      assignments: [
        {
          id: 2,
          projectId: 1234568,
          projectName: 'Project name 2',
          startDate: '2022-02-02',
          endDate: '2023-02-02',
          role: 'Utvecklare',
          extent: 100,
          extentType: ExtentType.Percent,
          contractType: ContractType.Expert,
          source: '',
        },
      ],
    },
    {
      customerId: 10,
      customerName: 'Testkund',
      id: 9,
      employeeId: 9,
      employeeEmail: 'test@example.com',
      employeeFirstName: 'Nisse',
      employeeLastName: 'Timmis',
      role: 'Scrum master och flera andra roller i detta projekt',
      extent: 164,
      extentType: ExtentType.Hourly,
      monthsWorked: 17,
      monthsLeftOfAssignment: 2,
      status: Status.NotHandled,
      lastDateOfAssignment: '2023-09-18',
      assignments: [
        {
          id: 2,
          projectId: 1234568,
          projectName: 'Project Lazarus Project Lazarus ',
          startDate: '2022-02-02',
          endDate: '2023-02-02',
          role: 'Scrum master och flera andra roller i detta projekt',
          extent: 164,
          extentType: ExtentType.Hourly,
          contractType: ContractType.Expert,
          source: '',
        },
      ],
    },
    {
      customerId: 12,
      customerName: 'Riksbyggen',
      id: 10,
      employeeId: 10,
      employeeEmail: 'helena.rordahl@forefront.se',
      employeeFirstName: 'Helena',
      employeeLastName: 'Rördahl',
      role: 'Systemutvecklare',
      extent: 100,
      extentType: ExtentType.Percent,
      monthsWorked: 12,
      monthsLeftOfAssignment: 8,
      status: Status.Handled,
      lastDateOfAssignment: '2023-12-15',
      statusUpdatedAt: '2024-02-10',
      comment: 'For whom be shall not call onto others.',
      assignments: [],
    },
    {
      customerId: 12,
      customerName: 'Riksbyggen',
      id: 11,
      employeeId: 11,
      employeeEmail: 'fredrik.olsson@forefront.se',
      employeeFirstName: 'Fredrik',
      employeeLastName: 'Olsson',
      role: 'Systemutvecklare',
      extent: 100,
      extentType: ExtentType.Percent,
      monthsWorked: 15,
      monthsLeftOfAssignment: 8,
      status: Status.Handled,
      lastDateOfAssignment: '2023-12-15',
      statusUpdatedAt: '2024-02-11',
      comment: 'Another comment for another employee.',
      assignments: [],
    },
    {
      customerId: 12,
      customerName: 'Riksbyggen',
      id: 13,
      employeeId: 13,
      employeeEmail: 'simon.gren@forefront.se',
      employeeFirstName: 'Simon',
      employeeLastName: 'Gren',
      role: 'Systemutvecklare',
      extent: 80,
      extentType: ExtentType.Percent,
      monthsWorked: 5,
      monthsLeftOfAssignment: 16,
      status: Status.Handled,
      lastDateOfAssignment: '2023-12-15',
      statusUpdatedAt: '2024-02-20',
      comment: 'Comment for Simon Gren. Simon Gren is a good employee.',
      assignments: [],
    },
  ],
};
