import { MaterialSymbol as MaterialSymbolType } from 'material-symbols';
import { CSSProperties } from 'react';

type SymbolStyleType = 'sharp' | 'outlined' | 'rounded';

interface MaterialSymbolInterface {
  icon: MaterialSymbolType;
  color?: string;
  fill?: boolean;
  size?: number;
  className?: string;
  symbolStyle?: SymbolStyleType;
  weight?: number;
}

export const MaterialSymbol = ({
  icon,
  color,
  fill,
  size,
  className,
  symbolStyle = 'sharp',
  weight,
}: MaterialSymbolInterface) => {
  const elementClasses = [`material-symbols-${symbolStyle}`];
  if (fill) elementClasses.push('material-symbols-fill');
  if (className) elementClasses.push(className);

  const style: CSSProperties = {};

  if (color) {
    style.color = color;
  }

  if (Number(size) >= 0) {
    style.overflow = 'hidden';
    style.fontSize = size;
    style.height = size;
    style.width = size;
  }

  if (Number(weight) >= 400) {
    if (fill)
      style.fontVariationSettings = `'FILL' 1, 'wght' ${weight}, 'GRAD' 0, 'opsz' 48`;
    else
      style.fontVariationSettings = `'FILL' 0, 'wght' ${weight}, 'GRAD' 0, 'opsz' 48`;
  }

  return (
    <span className={elementClasses.join(' ')} style={style} aria-hidden="true">
      {icon}
    </span>
  );
};
