import { MaterialSymbol } from '@components/MaterialSymbol';
import { useActiveUser } from '@context/ActiveUserContext';
import { useScreenSizeBreakpoint } from '@hooks/useScreenSizeBreakpoints';
import { useTheme } from '@mui/material';
import { getRoleLabel } from '@utils/getRoleLabel';
import { useState } from 'react';
import UserCard from './UserCard';
import {
  ButtonTextContent,
  ButtonTextContentName,
  ButtonTextContentRole,
  SmallAvatar,
  StyledUserButton,
} from './styles';

export const UserIconButton = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { activeUser } = useActiveUser();
  const name = activeUser?.name || '';
  const email = activeUser?.email || '';
  const activeRole = activeUser?.activeRole || '';
  const availableRoles = activeUser?.availableRoles || [];

  const theme = useTheme();
  const useCompactHeader = useScreenSizeBreakpoint('md');

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderButtonContent = () => {
    if (useCompactHeader) {
      return <SmallAvatar sizes="small">{email[0]}</SmallAvatar>;
    } else {
      return (
        <ButtonTextContent>
          <ButtonTextContentName>{name}</ButtonTextContentName>
          {availableRoles.length > 1 && (
            <ButtonTextContentRole>
              {getRoleLabel(activeRole)}
            </ButtonTextContentRole>
          )}
        </ButtonTextContent>
      );
    }
  };

  return (
    activeUser && (
      <>
        <StyledUserButton
          color="inherit"
          aria-label={`${name} ${getRoleLabel(activeRole)}`}
          onClick={handleClick}
          title={name}
          endIcon={
            !useCompactHeader && (
              <MaterialSymbol
                icon="keyboard_arrow_down"
                fill={true}
                size={30}
              />
            )
          }
          open={open}
          theme={theme}
          data-cy="user-icon-button"
        >
          {renderButtonContent()}
        </StyledUserButton>
        {open && (
          <UserCard
            open={open}
            anchorEl={anchorEl}
            handleClose={handleClose}
            name={name}
            email={email}
            activeRole={activeRole}
            availableRoles={availableRoles}
          />
        )}
      </>
    )
  );
};
