import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { PageLoadingSpinner } from '@components/PageLoadingSpinner';
import LoginPage from '@components/Pages/LoginPage';
import { environment } from '@environments/environment';
import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

interface ProtectedRouteElementProps {
  children: JSX.Element;
}
const ProtectedRouteElement = ({ children }: ProtectedRouteElementProps) => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const { inProgress, accounts } = useMsal();
  const isAuthenticated = environment.useAuth ? accounts.length > 0 : true;

  useEffect(() => {
    if (inProgress === InteractionStatus.None || !environment.useAuth) {
      setIsLoading(false);
    }
  }, [inProgress, isAuthenticated]);

  if (isLoading) return <PageLoadingSpinner />;
  if (isAuthenticated) return children;

  return location.pathname !== '/' ? <Navigate to="/" /> : <LoginPage />;
};

export default ProtectedRouteElement;
