import { LogoutItem } from '@components/LogoutItem';
import { MaterialSymbol } from '@components/MaterialSymbol';
import { UserRole } from '@enums/Enums';
import { useScreenSizeBreakpoint } from '@hooks/useScreenSizeBreakpoints';
import Divider from '@mui/material/Divider';
import UserRoleList from './UserRoleList';
import {
  CloseButton,
  Email,
  Name,
  StyledAvatar,
  StyledMenu,
  UserDetails,
  UserInfo,
} from './styles';

type UserCardInterface = {
  open: boolean;
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  email: string;
  activeRole: UserRole | '';
  availableRoles: UserRole[];
  name: string;
};

export default function UserCard({
  open,
  anchorEl,
  handleClose,
  email,
  activeRole,
  availableRoles,
  name,
}: UserCardInterface) {
  const showCloseButton = useScreenSizeBreakpoint('sm');

  return (
    <StyledMenu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      MenuListProps={{
        sx: {
          pb: 0,
        },
      }}
      PaperProps={{
        elevation: 0,
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      data-cy="user-card"
    >
      <UserInfo>
        <StyledAvatar>{email[0]}</StyledAvatar>
        <UserDetails>
          <Name data-cy="name">{name}</Name>
          <Email title={email} data-cy="email">
            {email}
          </Email>
        </UserDetails>
      </UserInfo>
      <Divider />
      <UserRoleList activeRole={activeRole} availableRoles={availableRoles} />
      <Divider />
      <LogoutItem />
      {showCloseButton && (
        <CloseButton tabIndex={0}>
          <MaterialSymbol icon="close" size={22} />
        </CloseButton>
      )}
    </StyledMenu>
  );
}
