import { HEADER_HEIGHT } from '@constants/constants';
import { AppBar, Button } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  height: HEADER_HEIGHT,
  display: 'flex',
  justifyContent: 'center',
  position: 'fixed',
}));

export const LogoWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: '#fff',
}));

export const Logo = styled('img')(() => ({
  height: '30px',
  width: 'auto',
  maxWidth: '100%',
}));

export const RightContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'center',
  marginLeft: theme.spacing(2),
}));

export const NavigationToggleButton = styled(Button)(({ theme }) => ({
  padding: '0',
  borderRadius: '10em',
  color: '#fff',
  minWidth: 0,
  backgroundColor: 'transparent',
  aspectRatio: 1,
  span: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));
