import Box, { BoxProps } from '@mui/material/Box';
import { Theme, styled } from '@mui/material/styles';

interface FallbackElementProps extends BoxProps {
  theme: Theme;
}

export const FallbackElement = styled(Box)(
  ({ theme }: FallbackElementProps) => ({
    backgroundColor: theme.palette.primary.main,
    height: '64px',
  }),
);
