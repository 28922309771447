import { environment } from '@environments/environment';
import { configureClientMocks } from '@utils/configureClientMocks';
import { configureEmployeeMocks } from '@utils/configureEmployeeMocks';
import { AxiosInstance } from 'axios';
import MockAdapter from 'axios-mock-adapter';

export const addMocks = (axios: AxiosInstance) => {
  // Get mock response delay
  const responseDelay = environment.mockDelay || 0;
  const mockAdapter = new MockAdapter(axios, { delayResponse: responseDelay });
  configureEmployeeMocks(mockAdapter);
  configureClientMocks(mockAdapter);
};
