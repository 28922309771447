import { Header } from '@components/Header';
import { useIsMsalAuthenticated } from '@hooks/useIsMsalAuthenticated';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { ReactNode, useState } from 'react';
import Navigation from '../Navigation/Navigation';

interface LayoutProps {
  children: ReactNode;
}
export function Layout(props: LayoutProps) {
  const isAuthenticated = useIsMsalAuthenticated();
  const [drawerOpen, setDrawerOpen] = useState(false);

  return isAuthenticated ? (
    <>
      <Header toggleNavigationDrawer={setDrawerOpen} />
      <Box sx={{ display: 'flex', minHeight: '100vh' }}>
        <Navigation drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
        <Container maxWidth="lg">{props.children}</Container>
      </Box>
    </>
  ) : (
    <div>{props.children}</div>
  );
}
export default Layout;
