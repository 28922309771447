import bgImage1080 from '@assets/background/404_1080.jpg';
import bgImage720 from '@assets/background/404_720.jpg';
import bgImageSmall from '@assets/background/404_small.jpg';
import { Header } from '@components/Header';
import { useScreenSizeBreakpoint } from '@hooks/useScreenSizeBreakpoints';
import { Box, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  NotFoundWrapper,
  StyledButton,
  StyledHeader,
  StyledParagraph,
  StyledSubHeader,
} from './styles';

const NotFoundPage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [source, setSource] = useState(bgImageSmall);
  const [isLoaded, setIsLoaded] = useState(false);
  const largeScreen = useScreenSizeBreakpoint('lg');

  useEffect(() => {
    // Lazy loading background image based on screensize (not responsive)
    const img = new Image();
    img.src = largeScreen ? bgImage1080 : bgImage720;
    img.onload = () => {
      setSource(largeScreen ? bgImage1080 : bgImage720);
      setIsLoaded(true);
    };
  }, [largeScreen]);

  return (
    <>
      <Header />
      <NotFoundWrapper theme={theme} bg={source} isLoaded={isLoaded}>
        <Box sx={{ px: 2 }}>
          <StyledHeader>404</StyledHeader>
          <StyledSubHeader>Åh nej!</StyledSubHeader>
          <StyledParagraph>
            Sidan du letar efter finns inte.<br></br>Ska vi gå till startsidan?
          </StyledParagraph>
          <StyledButton onClick={() => navigate('/')} variant="contained">
            Ta mig till startsidan
          </StyledButton>
        </Box>
      </NotFoundWrapper>
    </>
  );
};
export default NotFoundPage;
