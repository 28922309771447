import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import { routes } from '@routes/routes';
import { Link, useLocation } from 'react-router-dom';

import { MaterialSymbol } from '@components/MaterialSymbol';
import Roles from '@components/Role/Roles';
import { useScreenSizeBreakpoint } from '@hooks/useScreenSizeBreakpoints';
import ListItem from '@mui/material/ListItem';
import { useTheme } from '@mui/material/styles';
import { useEffect } from 'react';
import {
  CloseButton,
  Nav,
  StyledListButton,
  StyledListItemIcon,
} from './styles';

interface NavigationProps {
  drawerOpen: boolean;
  setDrawerOpen: (open: boolean) => void;
}

const Navigation = ({ drawerOpen, setDrawerOpen }: NavigationProps) => {
  const location = useLocation();
  const theme = useTheme();
  const drawerTogglable = useScreenSizeBreakpoint('md');
  const drawerIsOpen = drawerTogglable ? drawerOpen : true;
  const drawerVariant = drawerTogglable ? 'temporary' : 'permanent';

  // Don't close the drawer until the pathname changes.
  // Related bug: https://github.com/mui/material-ui/issues/36934
  // The bug is causing the drawer to get stuck in the opened state in some cases.
  // Unfortunately this workaround triggers an additional render on every route change.
  useEffect(() => {
    setDrawerOpen(false);
  }, [location.pathname, setDrawerOpen]);

  return (
    <Nav
      data-cy="navigation"
      aria-label="navigation"
      open={drawerIsOpen}
      variant={drawerVariant}
      anchor={drawerTogglable ? 'right' : 'left'}
      onClose={() => {
        setDrawerOpen(false);
      }}
    >
      <List component={'nav'}>
        {routes.map((route) => {
          if (route.showInNavigation === false) return null;

          return (
            <Roles roles={route.roles} key={route.path}>
              <ListItem
                component={Link}
                to={route.path}
                onClick={() => {
                  // Close the drawer directly if the user clicks on the current route.
                  if (route.path === location.pathname) {
                    setDrawerOpen(false);
                  }
                }}
                disablePadding
                sx={{
                  color: 'text.primary',
                }}
                data-cy="navigation-item"
              >
                <StyledListButton
                  active={route.path === location.pathname}
                  theme={theme}
                  tabIndex={-1}
                >
                  <StyledListItemIcon aria-hidden="true">
                    {route.path === location.pathname
                      ? route.iconFilled
                      : route.icon}
                  </StyledListItemIcon>
                  <ListItemText primary={route.name} disableTypography />
                </StyledListButton>
              </ListItem>
            </Roles>
          );
        })}
      </List>

      {drawerTogglable && (
        <CloseButton
          onClick={() => {
            setDrawerOpen(false);
          }}
          data-cy="navigation-close-button"
        >
          <MaterialSymbol size={40} icon="close" />
        </CloseButton>
      )}
    </Nav>
  );
};

export default Navigation;
