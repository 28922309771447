import { ContractType, Status } from '@enums/Enums';
import { EmployeeOnboardingResponseInterface } from '@interfaces/EmployeeOnboardingResponseInterface';
import moment from 'moment';

export const employeeOnboardingResponse: EmployeeOnboardingResponseInterface = {
  pageIndex: 0,
  totalPageCount: 1,
  totalRecordCount: 1,
  pageRecordCount: 1,
  records: [
    {
      employeeId: 1,
      employeeEmail: 'ninni.granlund@forefront.se',
      employeeFirstName: 'Ninni',
      employeeLastName: 'Granlund',
      role: 'Utvecklare',
      status: Status.NotHandled,
      team: 'Stockholm 1',
      lastEdited: moment().format('YYYY-MM-DD HH:mm:ss'),
      assignments: [
        {
          id: 1,
          startDate: '2022-01-01',
          endDate: '2023-05-01',
          contractType: ContractType.Onboarding,
          customerId: 1019,
          customerName: 'ABC Company',
          customerOrganizationNumber: 12345,
        },
        {
          id: 2,
          startDate: '2022-02-02',
          endDate: '2023-02-02',
          contractType: ContractType.Onboarding,
          customerId: 1020,
          customerName: 'XYZ Corporation',
          customerOrganizationNumber: 23456,
        },
        {
          id: 3,
          startDate: '2022-10-01',
          endDate: '2023-04-30',
          contractType: ContractType.Onboarding,
          customerId: 1021,
          customerName: 'PQR Industries',
          customerOrganizationNumber: 34567,
        },
        {
          id: 4,
          startDate: '2023-01-01',
          endDate: '2023-06-15',
          contractType: ContractType.Onboarding,
          customerId: 1022,
          customerName: 'LMN Corporation',
          customerOrganizationNumber: 45678,
        },
      ],
    },
    {
      employeeId: 2,
      employeeEmail: 'john.doe@forefront.se',
      employeeFirstName: 'John',
      employeeLastName: 'Doe',
      role: 'Scrum master',
      status: Status.NotHandled,
      team: 'Stockholm 1',
      lastEdited: moment().subtract(5, 'hours').format('YYYY-MM-DD HH:mm:ss'),
      assignments: [
        {
          id: 5,
          startDate: '2021-11-15',
          endDate: '2023-03-15',
          contractType: ContractType.Onboarding,
          customerId: 1023,
          customerName: 'DEF Corporation',
          customerOrganizationNumber: 56789,
        },
      ],
    },
    {
      employeeId: 3,
      employeeEmail: 'jane.smith@forefront.se',
      employeeFirstName: 'Jane',
      employeeLastName: 'Smith',
      role: 'Design lead',
      status: Status.NotHandled,
      team: 'Västerås 1',
      lastEdited: moment().subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss'),
      assignments: [],
    },
    {
      employeeId: 4,
      employeeEmail: 'susan.johnson@forefront.se',
      employeeFirstName: 'Susan',
      employeeLastName: 'Johnson',
      role: 'Utvecklare',
      status: Status.NotHandled,
      team: 'Östersund',
      lastEdited: '2023-10-10 14:30:00',
      assignments: [
        {
          id: 7,
          startDate: '2022-03-01',
          endDate: '2023-02-28',
          contractType: ContractType.Onboarding,
          customerId: 1024,
          customerName: 'JKL Corporation',
          customerOrganizationNumber: 78901,
        },
      ],
    },
    {
      employeeId: 5,
      employeeEmail: 'michael.smith@forefront.se',
      employeeFirstName: 'Michael',
      employeeLastName: 'Smith',
      role: 'Scrum master',
      status: Status.NotHandled,
      team: 'Stockholm 2',
      lastEdited: '2023-10-09 12:00:00',
      assignments: [
        {
          id: 8,
          startDate: '2021-11-01',
          endDate: '2023-05-01',
          contractType: ContractType.Onboarding,
          customerId: 1025,
          customerName: 'ABC Corporation',
          customerOrganizationNumber: 12345,
        },
      ],
    },
    {
      employeeId: 6,
      employeeEmail: 'lisa.anderson@forefront.se',
      employeeFirstName: 'Lisa',
      employeeLastName: 'Anderson',
      role: 'Design lead',
      status: Status.NotHandled,
      team: 'Sundsvall',
      lastEdited: '2023-10-08 10:00:00',
      assignments: [
        {
          id: 9,
          startDate: '2022-02-15',
          endDate: '2023-02-15',
          contractType: ContractType.Onboarding,
          customerId: 1026,
          customerName: 'MNO Corporation',
          customerOrganizationNumber: 89012,
        },
      ],
    },
    {
      employeeId: 7,
      employeeEmail: 'david.williams@forefront.se',
      employeeFirstName: 'David',
      employeeLastName: 'Williams',
      role: 'Utvecklare',
      status: Status.NotHandled,
      team: 'Malmö',
      lastEdited: '2023-10-06 08:00:00',
      assignments: [
        {
          id: 10,
          startDate: '2022-03-01',
          endDate: '2023-03-01',
          contractType: ContractType.Onboarding,
          customerId: 1027,
          customerName: 'PQR Corporation',
          customerOrganizationNumber: 45678,
        },
      ],
    },
    {
      employeeId: 8,
      employeeEmail: 'emily.jones@forefront.se',
      employeeFirstName: 'Emily',
      employeeLastName: 'Jones',
      role: 'Scrum master',
      status: Status.NotHandled,
      team: 'Linköping',
      lastEdited: '2023-10-05 10:00:00',
      assignments: [
        {
          id: 11,
          startDate: '2022-01-15',
          endDate: '2023-05-15',
          contractType: ContractType.Onboarding,
          customerId: 1028,
          customerName: 'Account Official',
          customerOrganizationNumber: 45678,
        },
      ],
    },
    {
      employeeId: 9,
      employeeEmail: 'robert.brown@forefront.se',
      employeeFirstName: 'Robert',
      employeeLastName: 'Brown',
      role: 'Design lead',
      status: Status.NotHandled,
      team: 'Jönköping',
      lastEdited: '2023-10-04 12:00:00',
      assignments: [
        {
          id: 12,
          startDate: '2022-02-20',
          endDate: '2023-02-20',
          contractType: ContractType.Onboarding,
          customerId: 1029,
          customerName: 'Headalready AB',
          customerOrganizationNumber: 45678,
        },
      ],
    },
    {
      employeeId: 10,
      employeeEmail: 'sarah.davis@forefront.se',
      employeeFirstName: 'Sarah',
      employeeLastName: 'Davis',
      role: 'Utvecklare',
      status: Status.NotHandled,
      team: 'Stockholm 3',
      lastEdited: '2023-10-03 14:00:00',
      assignments: [
        {
          id: 13,
          startDate: '2022-03-05',
          endDate: '2023-03-05',
          contractType: ContractType.Onboarding,
          customerId: 1030,
          customerName: 'Cage ready',
          customerOrganizationNumber: 45678,
        },
      ],
    },
    {
      employeeId: 11,
      employeeEmail: 'james.moore@forefront.se',
      employeeFirstName: 'James',
      employeeLastName: 'Moore',
      role: 'Scrum master',
      status: Status.NotHandled,
      team: 'Stockholm 4',
      lastEdited: '2023-09-25 16:00:00',
      assignments: [
        {
          id: 14,
          startDate: '2021-10-15',
          endDate: '2023-04-15',
          contractType: ContractType.Onboarding,
          customerId: 1031,
          customerName: 'Careful adventure',
          customerOrganizationNumber: 45678,
        },
      ],
    },
    {
      employeeId: 12,
      employeeEmail: 'linda.clark@forefront.se',
      employeeFirstName: 'Linda',
      employeeLastName: 'Clark',
      role: 'Design lead',
      status: Status.NotHandled,
      team: 'Halmstad',
      lastEdited: '2023-09-20 18:00:00',
      assignments: [
        {
          id: 15,
          startDate: '2022-01-10',
          endDate: '2023-01-10',
          contractType: ContractType.Onboarding,
          customerId: 1032,
          customerName: 'Finest wic',
          customerOrganizationNumber: 45678,
        },
      ],
    },
    {
      employeeId: 13,
      employeeEmail: 'peter.wilson@forefront.se',
      employeeFirstName: 'Peter',
      employeeLastName: 'Wilson',
      role: 'Utvecklare',
      status: Status.NotHandled,
      team: 'Stockholm 1',
      lastEdited: '2023-09-15 20:00:00',
      assignments: [
        {
          id: 16,
          startDate: '2022-03-01',
          endDate: '2023-03-01',
          contractType: ContractType.Onboarding,
          customerId: 1033,
          customerName: 'Worder',
          customerOrganizationNumber: 45678,
        },
      ],
    },
    {
      employeeId: 14,
      employeeEmail: 'elizabeth.hall@forefront.se',
      employeeFirstName: 'Elizabeth',
      employeeLastName: 'Hall',
      role: 'Scrum master',
      status: Status.NotHandled,
      team: 'Stockholm 1',
      lastEdited: '2023-09-10 22:00:00',
      assignments: [
        {
          id: 17,
          startDate: '2021-12-15',
          endDate: '2023-05-15',
          contractType: ContractType.Onboarding,
          customerId: 1034,
          customerName: 'Traphurr Viz',
          customerOrganizationNumber: 45678,
        },
      ],
    },
    {
      employeeId: 15,
      employeeEmail: 'william.lewis@forefront.se',
      employeeFirstName: 'William',
      employeeLastName: 'Lewis',
      role: 'Design lead',
      status: Status.NotHandled,
      team: 'Jönköping',
      lastEdited: '2023-07-12 10:00:00',
      assignments: [
        {
          id: 18,
          startDate: '2022-02-20',
          endDate: '2023-02-20',
          contractType: ContractType.Onboarding,
          customerId: 1035,
          customerName: 'Washy Corp',
          customerOrganizationNumber: 45678,
        },
      ],
    },
    {
      employeeId: 16,
      employeeEmail: 'laura.martin@forefront.se',
      employeeFirstName: 'Laura',
      employeeLastName: 'Martin',
      role: 'Utvecklare',
      status: Status.NotHandled,
      team: 'Åre',
      lastEdited: '2023-07-10 10:00:00',
      assignments: [
        {
          id: 19,
          startDate: '2022-03-05',
          endDate: '2023-03-05',
          contractType: ContractType.Onboarding,
          customerId: 1036,
          customerName: 'Fire department',
          customerOrganizationNumber: 45678,
        },
      ],
    },
    {
      employeeId: 17,
      employeeEmail: 'daniel.walker@forefront.se',
      employeeFirstName: 'Daniel',
      employeeLastName: 'Walker',
      role: 'Scrum master',
      status: Status.NotHandled,
      team: 'Åre',
      lastEdited: '2023-07-05 10:00:00',
      assignments: [
        {
          id: 20,
          startDate: '2021-10-15',
          endDate: '2023-04-15',
          contractType: ContractType.Onboarding,
          customerId: 1037,
          customerName: 'Meat House',
          customerOrganizationNumber: 45678,
        },
      ],
    },
    {
      employeeId: 18,
      employeeEmail: 'patricia.white@forefront.se',
      employeeFirstName: 'Patricia',
      employeeLastName: 'White',
      role: 'Design lead',
      status: Status.NotHandled,
      team: 'Sundsvall',
      lastEdited: '2023-07-01 10:00:00',
      assignments: [
        {
          id: 21,
          startDate: '2022-01-10',
          endDate: '2023-01-10',
          contractType: ContractType.Onboarding,
          customerId: 1038,
          customerName: 'Teachers union',
          customerOrganizationNumber: 45678,
        },
      ],
    },
    {
      employeeId: 19,
      employeeEmail: 'richard.harris@forefront.se',
      employeeFirstName: 'Richard',
      employeeLastName: 'Harris',
      role: 'Utvecklare',
      status: Status.NotHandled,
      team: 'Stockholm 1',
      lastEdited: '2023-06-28 20:00:00',
      assignments: [
        {
          id: 22,
          startDate: '2022-03-01',
          endDate: '2023-03-01',
          contractType: ContractType.Onboarding,
          customerId: 1039,
          customerName: 'Folksam',
          customerOrganizationNumber: 45678,
        },
      ],
    },
    {
      employeeId: 20,
      employeeEmail: 'mary.jackson@forefront.se',
      employeeFirstName: 'Mary',
      employeeLastName: 'Jackson',
      role: 'Scrum master',
      status: Status.NotHandled,
      team: 'Stockholm 2',
      lastEdited: '2022-06-25 18:00:00',
      assignments: [
        {
          id: 23,
          startDate: '2021-12-15',
          endDate: '2023-05-15',
          contractType: ContractType.Onboarding,
          customerId: 1040,
          customerName: 'Sun everywhere',
          customerOrganizationNumber: 45678,
        },
      ],
    },
    {
      employeeId: 21,
      employeeEmail: 'charles.ward@forefront.se',
      employeeFirstName: 'Charles',
      employeeLastName: 'Ward',
      role: 'Design lead',
      status: Status.NotHandled,
      team: 'Stockholm 1',
      lastEdited: '2022-06-20 16:00:00',
      assignments: [
        {
          id: 24,
          startDate: '2022-02-20',
          endDate: '2023-02-20',
          contractType: ContractType.Onboarding,
          customerId: 1041,
          customerName: 'Vegan food',
          customerOrganizationNumber: 45678,
        },
      ],
    },
    {
      employeeId: 22,
      employeeEmail: 'natalie.green@forefront.se',
      employeeFirstName: 'Natalie',
      employeeLastName: 'Green',
      role: 'Utvecklare',
      status: Status.NotHandled,
      team: 'Östersund',
      lastEdited: '2022-06-15 14:00:00',
      assignments: [
        {
          id: 25,
          startDate: '2022-03-05',
          endDate: '2023-03-05',
          contractType: ContractType.Onboarding,
          customerId: 1042,
          customerName: 'Headed forward Inc.',
          customerOrganizationNumber: 45678,
        },
      ],
    },
  ],
};
