import { devRolesKey } from '@constants/constants';
import { UserRole } from '@enums/Enums';
import { environment } from '@environments/environment';
import { isJSON } from './isJSON';

export const getDeveloperUserRoles = () => {
  // If we're not in dev mode, return an empty array
  if (environment.production) return [];

  const developerRoles = [] as UserRole[];

  const savedDevRoles = window.localStorage.getItem(devRolesKey) || '';
  const parsedDevRoles = isJSON(savedDevRoles) ? JSON.parse(savedDevRoles) : [];

  const devRoles = !environment.production
    ? parsedDevRoles || [UserRole.Dev]
    : [];

  // Append the saved dev roles to the list of available user roles if it's not already there
  devRoles.forEach((role: UserRole) => {
    if (!developerRoles.includes(role)) {
      developerRoles.push(role);
    }
  });

  return developerRoles;
};
