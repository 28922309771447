import { useMsal } from '@azure/msal-react';
import { UserRole } from '@enums/Enums';
import { environment } from '@environments/environment';
import { useIsMsalAuthenticated } from '@hooks/useIsMsalAuthenticated';
import { UserContextInterface } from '@interfaces/UserContextInterface';
import { getDeveloperUserRoles } from '@utils/developerUserRoles';
import { getSavedUserRole } from '@utils/userRole';
import {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';

interface ActiveUserContext {
  activeUser: UserContextInterface | null;
  loading: boolean;
}
export const ActiveUserContext = createContext<ActiveUserContext>({
  activeUser: null,
  loading: false,
});
interface ActiveUserProviderProps {
  children: ReactElement;
}
export const ActiveUserProvider = ({ children }: ActiveUserProviderProps) => {
  const [user, setUser] = useState<UserContextInterface | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { instance } = useMsal();
  const isAuthenticated = useIsMsalAuthenticated();
  const [params] = useSearchParams();

  useEffect(() => {
    if (isAuthenticated) {
      const activeUser = instance.getActiveAccount();
      const userObject: UserContextInterface = {
        name: activeUser?.name || '',
        email: activeUser?.username || '',
        activeRole: UserRole.None,
        availableRoles: [UserRole.None],
      };

      const userRolesFromAD =
        (activeUser?.idTokenClaims?.roles as UserRole[]) || ([] as UserRole[]);

      // Set user info for testing when not using auth
      if (!environment.production && !environment.useAuth) {
        userObject.name = 'Namn Namnsson';
        userObject.email = 'namn.namnsson@forefront.se';
      }

      // Add developer roles if not in production
      if (!environment.production) {
        const devRoles = getDeveloperUserRoles();
        if (devRoles && devRoles.length > 0) {
          // Remove all roles from AD and add the developer roles
          userRolesFromAD.splice(0, userRolesFromAD.length, ...devRoles);
        }
      }

      // Set the previously saved role if it's set and available for the user
      const savedUserRole = getSavedUserRole();
      if (savedUserRole && userRolesFromAD.includes(savedUserRole)) {
        userObject.activeRole = savedUserRole;
      } else {
        // Else set the the first available role, with admin as the first priority
        if (userRolesFromAD.includes(UserRole.Admin)) {
          userObject.activeRole = UserRole.Admin;
        } else if (userRolesFromAD.includes(UserRole.User)) {
          userObject.activeRole = UserRole.User;
        }
      }
      userObject.availableRoles = userRolesFromAD as UserRole[];

      setUser(userObject);
      setLoading(false);
    } else {
      setUser(null);
    }
  }, [isAuthenticated, instance, params]);

  return (
    <ActiveUserContext.Provider
      value={{
        activeUser: user,
        loading,
      }}
    >
      {children}
    </ActiveUserContext.Provider>
  );
};

export const useActiveUser = () => useContext(ActiveUserContext);
