export const MENU_WIDTH = 200;
export const HEADER_HEIGHT = 64;

export const alertStrings = {
  EMPLOYEE_CLOSE_TO_PASSING_24_MONTHS:
    'Konsulten närmar sig 24 månader hos kund.',
  EMPLOYEE_WILL_PASS_24_MONTHS:
    'Konsulten kommer att överskrida 24 månader hos kund.',
  EMPLOYEE_RECEIVED_OFFER:
    'Konsulten har fått ett anställningserbjudande, eller erbjudits två månadslöner.',
};

export const missingOrgNumberString = '[Org-nr saknas]';

export const devRolesKey = 'developer-roles';
export const selectedUserRoleKey = 'selected-user-role';
