import {
  AuthenticationResult,
  EventMessage,
  EventType,
  PublicClientApplication,
} from '@azure/msal-browser';
import { msalConfig } from '@config/authConfig';
import { setMomentDefaults } from '@config/momentConfig';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import 'material-symbols';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './app/app';
import './styles.scss';

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const token = payload.accessToken;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
    sessionStorage.setItem('authorization-token', token);
  }
});

setMomentDefaults();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <StrictMode>
    <StyledEngineProvider injectFirst>
      <App publicClientApplication={msalInstance} />
    </StyledEngineProvider>
  </StrictMode>,
);
