import { UserRole } from '@enums/Enums';

export const getRoleLabel = (role: string) => {
  switch (role) {
    case UserRole.Admin:
      return 'Stödfunktion';
    case UserRole.User:
      return 'Kundansvarig';
    case UserRole.Dev:
      return 'Utvecklare';
    default:
      return 'Utböling';
  }
};
