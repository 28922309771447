import { useScreenSizeBreakpoint } from '@hooks/useScreenSizeBreakpoints';
import {
  Avatar,
  Box,
  Button,
  List,
  ListItemIcon,
  Menu,
  MenuItem,
  Theme,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

interface StyledUserButtonProps {
  theme: Theme;
  open: boolean;
}

export const StyledUserButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }: StyledUserButtonProps) => {
  const useCompactHeader = useScreenSizeBreakpoint('md');
  return {
    letterSpacing: '0.02em',
    color: theme.palette.common.white,
    borderRadius: '10em',
    paddingRight: useCompactHeader ? theme.spacing(0.75) : theme.spacing(2),
    paddingLeft: useCompactHeader ? theme.spacing(0.75) : theme.spacing(2),
    minWidth: 0,
    minHeight: '42px',
    '&:hover': {
      backgroundColor: '#3333337A',
    },
    ...(open && {
      backgroundColor: '#3333337A',
    }),
  };
});

export const ButtonTextContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  paddingRight: theme.spacing(1),
}));

export const ButtonTextContentName = styled(Typography)(({ theme }) => ({
  textTransform: 'uppercase',
  fontWeight: 700,
  fontSize: '1rem',
}));

export const ButtonTextContentRole = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  fontWeight: 400,
  marginTop: '-2px',
}));

export const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    overflow: 'visible',
    marginTop: '5px',
    borderRadius: '5px',
    backgroundColor: theme.palette.background.default,
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.25)',
    width: '300px',
  },
}));

export const StyledMenuItem = styled(MenuItem)(() => ({
  maxWidth: '100%',
  overflow: 'hidden',
  fontSize: '0.875rem',
}));

export const UserInfo = styled(MenuItem)(({ theme }) => ({
  pointerEvents: 'none',
  padding: '16px 16px 8px',
  '&.MuiMenuItem-root.Mui-focusVisible': {
    backgroundColor: theme.palette.background.default,
  },
}));

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: '48px',
  height: '48px',
  marginRight: theme.spacing(2),
  textTransform: 'uppercase',
  fontSize: '1.5rem',
}));

const fontWeight = 400;
const fontSize = '0.87rem';

export const Name = styled(Typography)(({ theme }) => ({
  fontWeight,
  textTransform: 'capitalize',
  color: theme.palette.grey[800],
}));

export const Email = styled(Typography)(({ theme }) => ({
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize,
  color: theme.palette.grey[600],
}));

export const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  width: '20px',
  height: '20px',
  minWidth: 'unset !important',
  marginRight: theme.spacing(1),
  marginLeft: '0px',
}));

export const StyledList = styled(List)(({ theme }) => ({
  borderBottom: 'solid 1px #E5E5E5',
}));

export const SmallAvatar = styled(StyledAvatar)(({ theme }) => ({
  width: '32px',
  height: '32px',
  marginRight: 0,
  fontSize: '1rem',
}));

export const UserDetails = styled(Box)(({ theme }) => ({
  display: 'flex',
  overflow: 'hidden',
  flexDirection: 'column',
  maxWidth: '100%',
  flex: '1 1 auto',
}));

export const CloseButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  padding: 2,
  borderRadius: '10em',
  minWidth: 0,
  '&:hover': {
    backgroundColor: '#3333337A',
  },
}));
