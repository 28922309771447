import { IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import AppRouter from '@components/AppRouter';
import { ConditionalWrapper } from '@components/ConditionalWrapper/ConditionalWrapper';
import DeveloperSettings from '@components/DeveloperSettings';
import { environment } from '@environments/environment';
import CssBaseline from '@mui/material/CssBaseline';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { addMocks } from '@utils/addMocks';
import axios from 'axios';
import { lightTheme } from '../styles/theme';

type AppProps = {
  publicClientApplication: IPublicClientApplication;
};

export function App({ publicClientApplication }: AppProps) {
  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      <ConditionalWrapper
        condition={environment.useAuth}
        wrapper={(children) => (
          <MsalProvider instance={publicClientApplication}>
            {children}
          </MsalProvider>
        )}
      >
        <>
          <AppRouter />
          {!environment.production && <DeveloperSettings />}
        </>
      </ConditionalWrapper>
    </ThemeProvider>
  );
}

if (environment.useMocks) {
  addMocks(axios);
}

export default App;
