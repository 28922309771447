import { devRolesKey } from '@constants/constants';
import { UserRole } from '@enums/Enums';
import { Box } from '@mui/material';
import { isJSON } from '@utils/isJSON';
import { useRef } from 'react';

export const RoleSwitcher = () => {
  const selectElement = useRef<HTMLSelectElement>(null);

  // Get the selected roles from the select element
  const getSelectedRoles = (selectElement: HTMLSelectElement | null) => {
    if (!selectElement) return [];

    const selectedRoles = [];
    for (let i = 0; i < selectElement.options.length; i++) {
      const option = selectElement.options[i];
      if (option.selected) {
        selectedRoles.push(option.value);
      }
    }
    return selectedRoles;
  };

  const updateRoles = () => {
    const selectedRoles = getSelectedRoles(selectElement.current);

    window.localStorage.setItem(devRolesKey, JSON.stringify(selectedRoles));
    window.location.reload();
  };

  const resetRoles = () => {
    window.localStorage.removeItem(devRolesKey);
    window.location.reload();
  };

  const currentDevRoleValue = window.localStorage.getItem(devRolesKey) || '';
  const currentDevRoles = isJSON(currentDevRoleValue)
    ? JSON.parse(currentDevRoleValue)
    : [];

  return (
    <Box aria-hidden="true">
      <label htmlFor="roles">Give user specific roles</label>
      <select
        name="role"
        id="roles"
        defaultValue={currentDevRoles}
        multiple={true}
        style={{ width: '100%' }}
        ref={selectElement}
      >
        {Object.values(UserRole).map((role) => (
          <option key={role} value={role}>
            {role}
          </option>
        ))}
      </select>
      <button onClick={() => updateRoles()}>Save</button>
      <button style={{ fontSize: '0.8rem' }} onClick={(e) => resetRoles()}>
        Reset to default roles
      </button>
    </Box>
  );
};
