import { Box, BoxProps, styled, Theme, Typography } from '@mui/material';
interface BoxThemeProps extends BoxProps {
  theme: Theme;
}
interface ThemeProps {
  theme: Theme;
}
interface BackgroundImageProps extends BoxThemeProps {
  bg: string;
  isLoaded: boolean;
}

const LoginWrapper = styled(Box, {
  shouldForwardProp: (props) => props !== 'isLoaded',
})(({ theme, bg, isLoaded }: BackgroundImageProps) => ({
  backgroundImage: `url(${bg})`,
  backgroundSize: 'cover',
  backgroundPosition: 'bottom right',
  backgroundColor: theme.palette.primary.main,
  height: '100vh',
  width: '100vw',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  '&::after': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100vw',
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: 1,
    backdropFilter: isLoaded ? undefined : 'blur(80px)',
  },
}));

const LoginContainer = styled(Box)(({ theme }: BoxThemeProps) => ({
  backgroundColor: theme.palette.common.white,
  width: '100%',
  maxWidth: '26rem',
  padding: '30px 24px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  zIndex: 2,
}));

const LoginDesc = styled(Typography)(() => ({
  fontSize: '1rem',
  '@media (min-width:840px)': {
    fontSize: '1.25rem',
  },
}));

const ForeFrontLogo = styled('img')(({ theme }: ThemeProps) => ({
  marginBottom: theme.spacing(5),
  zIndex: 2,
}));

export { ForeFrontLogo, LoginContainer, LoginDesc, LoginWrapper };
