import { MaterialSymbol } from '@components/MaterialSymbol';
import { UserRole } from '@enums/Enums';

import { getRoleLabel } from '@utils/getRoleLabel';
import { switchUserRole } from '@utils/userRole';
import { StyledListItemIcon, StyledMenuItem } from '../styles';

interface UserRoleListProps {
  activeRole: UserRole | '';
  availableRoles: UserRole[];
}

const UserRoleList = ({ activeRole, availableRoles }: UserRoleListProps) => {
  return (
    <>
      {availableRoles.map((role) => (
        <StyledMenuItem
          key={role}
          onClick={() => switchUserRole(role)}
          data-cy="role-item"
          aria-label={getRoleLabel(role)}
          {...(role === activeRole && { 'data-active': true })}
        >
          <StyledListItemIcon>
            {role === activeRole && <MaterialSymbol icon="check" size={20} />}
          </StyledListItemIcon>
          {getRoleLabel(role)}
        </StyledMenuItem>
      ))}
    </>
  );
};
export default UserRoleList;
