import { Box, BoxProps, Button, Theme, alpha } from '@mui/material';
import { styled } from '@mui/material/styles';

interface BoxThemeProps extends BoxProps {
  theme: Theme;
}

interface BackgroundImageProps extends BoxThemeProps {
  bg: string;
  isLoaded: boolean;
}

export const Main = styled('main')(() => ({
  width: '648px',
  margin: 'auto',
}));

const NotFoundWrapper = styled(Box, {
  shouldForwardProp: (props) => props !== 'isLoaded',
})(({ theme, bg, isLoaded }: BackgroundImageProps) => ({
  backgroundImage: `url(${bg})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  backgroundColor: theme.palette.primary.main,
  height: 'calc(100vh - 64px)',
  width: '100vw',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  color: '#fff',
}));

export { NotFoundWrapper };

export const StyledHeader = styled('h1')(({ theme }) => ({
  fontSize: '5rem',
  fontWeight: 700,
  margin: 0,
  lineHeight: 1.5,

  [theme.breakpoints.up('sm')]: {
    marginLeft: '-20%',
    fontSize: '10rem',
  },

  [theme.breakpoints.up('md')]: {
    marginLeft: '-50%',
  },
}));

export const StyledSubHeader = styled('h1')(({ theme }) => ({
  fontSize: '2.5rem',
  fontWeight: 500,
  margin: 0,
  mb: '1rem',
}));

export const StyledParagraph = styled('p')(({ theme }) => ({
  fontSize: '1.25rem',
  fontWeight: 400,
  margin: 0,
  marginBottom: '2rem',
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  borderColor: 'red',
  backgroundColor: theme.palette.primary.contrastText,
  color: theme.palette.common.white,
  textTransform: 'uppercase',
  fontWeight: 700,
  fontSize: '1rem',
  borderRadius: '10em',
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.contrastText, 0.8),
    boxShadow: 'none',
  },
}));
