import { ClientInterface } from '@interfaces/ClientInterface';
import { clientList } from '@mockdata/mockedClientListData';
import MockAdapter from 'axios-mock-adapter';

export function configureClientMocks(mockAdapter: MockAdapter) {
  mockAdapter.onGet('/api/v1/customers').reply(() => {
    const clientRecords = clientList.records;
    // Sort by name
    clientRecords.sort((a, b) => a.name.localeCompare(b.name));
    const sortedClientsList = {
      ...clientList,
      records: clientRecords,
    };

    return [200, sortedClientsList];
  });

  mockAdapter.onPost('/api/v1/customers').reply((config) => {
    const client = JSON.parse(config.data) as ClientInterface;

    const newId = clientList.records.length + 1000;
    const newClient = {
      ...client,
      id: newId,
    };
    clientList.records.push(newClient);

    return [201, newClient];
  }, 201);
}
