import { ClientResponseInterface } from '@interfaces/ClientResponseInterface';

export const clientList: ClientResponseInterface = {
  pageIndex: 0,
  totalPageCount: 1,
  totalRecordCount: 1,
  pageRecordCount: 1,
  records: [
    {
      id: 101,
      name: 'TRR',
      organizationNumber: '123456',
    },
    {
      id: 102,
      name: 'Acme Inc.',
      organizationNumber: '789012',
    },
    {
      id: 103,
      name: 'XYZ Corp.',
      organizationNumber: '345678',
    },
    {
      id: 104,
      name: 'Globex Corporation',
      organizationNumber: '901234',
    },
    {
      id: 105,
      name: 'Initech',
      organizationNumber: '567890',
    },
    {
      id: 106,
      name: 'Wayne Enterprises',
      organizationNumber: '123789',
    },
    {
      id: 107,
      name: 'Stark Industries',
      organizationNumber: '456123',
    },
    {
      id: 108,
      name: 'Umbrella Corporation',
      organizationNumber: '789456',
    },
    {
      id: 109,
      name: 'Weyland-Yutani Corporation',
      organizationNumber: '159753',
    },
    {
      id: 110,
      name: 'Tyrell Corporation',
      organizationNumber: '753159',
    },
    {
      id: 111,
      name: 'Oscorp Industries',
      organizationNumber: '246801',
    },
    {
      id: 112,
      name: 'HDI Enterprises',
      organizationNumber: '789456',
    },
    {
      id: 113,
      name: 'Daily Bugle',
      organizationNumber: '159753',
    },
    {
      id: 114,
      name: 'Gotham Gazette',
      organizationNumber: '753159',
    },
    {
      id: 115,
      name: 'Metropolis Daily Planet',
      organizationNumber: '246801',
    },
    {
      id: 116,
      name: 'S.H.I.E.L.D.',
      organizationNumber: '135790',
    },
  ],
};
