import { Layout } from '@components/Layout';
import ProtectedRouteElement from '@components/ProtectedRouteElement';
import Roles from '@components/Role/Roles';
import { ActiveUserProvider } from '@context/ActiveUserContext';
import { useTheme } from '@mui/material/styles';
import { routes } from '@routes/routes';
import { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ErrorPage from '../Pages/ErrorPage';
import NotFoundPage from '../Pages/NotFoundPage.tsx';
import { FallbackElement } from './styles';

const Fallback = () => {
  const theme = useTheme();
  return <FallbackElement theme={theme} />;
};

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Fallback />}>
        <Routes>
          {routes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={
                <ActiveUserProvider>
                  <ProtectedRouteElement>
                    <Roles roles={route.roles} path={route.path}>
                      <Layout>{route.element}</Layout>
                    </Roles>
                  </ProtectedRouteElement>
                </ActiveUserProvider>
              }
            />
          ))}
          <Route key="error" path="/error" element={<ErrorPage />} />
          <Route
            key="404"
            path="*"
            element={
              <ActiveUserProvider>
                <NotFoundPage />
              </ActiveUserProvider>
            }
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default AppRouter;
