import { Alert, Box, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { useLocation } from 'react-router-dom';

export const ErrorPage = () => {
  const { state } = useLocation();

  return (
    <Container
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box pt={4}>
        <Alert severity="error">
          <Typography variant="body1">dsadasd {state}</Typography>
        </Alert>
      </Box>
    </Container>
  );
};

export default ErrorPage;
