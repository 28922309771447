import { ContractType } from '@enums/Enums';
import { EmployeeInterface } from '@interfaces/EmployeeInterface';
import { clientList } from '@mockdata/mockedClientListData';
import { employeeSummaryResponse } from '@mockdata/mockedConsultantListData';
import { employeeOnboardingResponse } from '@mockdata/mockedOnboardingConsultantListData';
import MockAdapter from 'axios-mock-adapter';

export function configureEmployeeMocks(mockAdapter: MockAdapter) {
  mockAdapter.onGet('/api/v1/employee-customer-summaries').reply(() => {
    return [200, employeeSummaryResponse];
  });

  mockAdapter
    .onGet('/api/v1/employee-customer-summaries/account-manager')
    .reply(() => {
      return [200, employeeSummaryResponse];
    });

  mockAdapter
    .onPatch(/\/api\/v1\/employee-customer-summaries\/\d+/)
    .reply((config) => {
      const id = config.url?.split('/').pop();

      if (!id) return [400];

      if (
        !employeeSummaryResponse.records.find(
          (employee) => employee.id === Number(id),
        )
      ) {
        return [404];
      }

      const data = JSON.parse(config.data);
      if (data.status === undefined && data.comment === undefined) return [400];

      if (data.comment === 'error') return [500];

      // Update the status of the correct employee
      employeeSummaryResponse.records.forEach((employee) => {
        if (employee.id === Number(id)) {
          if (data.status !== undefined) {
            employee.status = data.status;
          }

          if (data.comment !== undefined) {
            employee.comment = data.comment;
          }

          if (data.statusUpdatedAt !== undefined) {
            employee.statusUpdatedAt = data.statusUpdatedAt;
          }
        }
      });

      return [204];
    });

  mockAdapter
    .onGet('/api/v1/employee-customer-summaries/onboarding')
    .reply(() => {
      const employeesWithAssignments =
        employeeOnboardingResponse.records.filter((employee) => {
          return employee.assignments?.length !== 0;
        });

      const newResponse = {
        ...employeeOnboardingResponse,
        records: employeesWithAssignments,
      };
      return [200, newResponse];
    });

  mockAdapter
    .onDelete(/\/api\/v1\/employee-customer-summaries\/assignments\/\d+/)
    .reply((config) => {
      const id = config.url?.split('/').pop();

      employeeOnboardingResponse.records.forEach((employee) => {
        employee.assignments = employee.assignments?.filter((assignment) => {
          return assignment.id !== Number(id);
        });
      });

      return [200];
    });

  mockAdapter.onGet('/api/v1/employees').reply(() => {
    const returnObject = {
      pageIndex: 0,
      totalPageCount: 1,
      totalRecordCount: 1,
      pageRecordCount: 1,
      records: [] as EmployeeInterface[],
    };

    const employees = employeeSummaryResponse.records.map((employee) => {
      return {
        id: employee.id,
        firstName: employee.employeeFirstName,
        lastName: employee.employeeLastName,
        email: employee.employeeEmail,
        team: {
          id: 0,
        },
      };
    });
    returnObject.records = employees;

    return [200, returnObject];
  });

  mockAdapter
    .onPost('/api/v1/employee-customer-summaries/assignments')
    .reply((config) => {
      const { data } = config;
      const { employeeId, customerId, assignment, employer } = JSON.parse(data);
      const { startDate, endDate } = assignment;

      const customer = clientList.records.find((customer) => {
        return customerId === customer.id;
      });

      let createdAssignmentId: number | undefined = undefined;

      employeeOnboardingResponse.records.forEach((employee) => {
        if (employee.employeeId === employeeId) {
          createdAssignmentId = Math.round(Math.random() * 1000 + 1000);
          const newAssignment = {
            id: createdAssignmentId,
            customerId: customerId,
            customerName: customer?.name || '',
            customerOrganizationNumber: customerId,
            startDate,
            endDate,
            employer,
            contractType: ContractType.Onboarding,
          };
          employee.assignments?.push(newAssignment);
        }
      });

      if (createdAssignmentId) {
        return [201, createdAssignmentId];
      }

      return [404];
    });
}
