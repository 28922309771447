import PageLoadingSpinnerContainer from './styles';
import { CircularProgress } from '@mui/material';

export const PageLoadingSpinner: React.FC = () => {
  return (
    <PageLoadingSpinnerContainer>
      <CircularProgress size={50} />
    </PageLoadingSpinnerContainer>
  );
};
