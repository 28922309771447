import { MaterialSymbol } from '@components/MaterialSymbol';
import { UserIconButton } from '@components/UserIconButton';
import { useScreenSizeBreakpoint } from '@hooks/useScreenSizeBreakpoints';
import Toolbar from '@mui/material/Toolbar';
import { Dispatch, SetStateAction } from 'react';
import {
  Logo,
  LogoWrapper,
  NavigationToggleButton,
  RightContainer,
  StyledAppBar,
} from './styles';

interface HeaderProps {
  toggleNavigationDrawer?: Dispatch<SetStateAction<boolean>>;
}

export function Header({ toggleNavigationDrawer }: HeaderProps) {
  const useCompactHeader = useScreenSizeBreakpoint('md');

  return (
    <StyledAppBar elevation={0}>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <LogoWrapper>
          <Logo
            src="/assets/forefront-logo.svg"
            alt="Forefront Consulting logotyp"
            loading="lazy"
            width="150"
          />
        </LogoWrapper>
        <RightContainer>
          <UserIconButton />
          {useCompactHeader && toggleNavigationDrawer && (
            <NavigationToggleButton
              onClick={() => toggleNavigationDrawer(true)}
              data-cy="navigation-toggle-button"
            >
              <MaterialSymbol color="#fff" icon="menu" size={40} />
            </NavigationToggleButton>
          )}
        </RightContainer>
      </Toolbar>
    </StyledAppBar>
  );
}

export default Header;
