import { svSE as coreSvSE } from '@mui/material/locale';
import { createTheme } from '@mui/material/styles';
import { svSE } from '@mui/x-date-pickers/locales';

export const textFieldFontSize = '16px';
export const textFieldPadding = '11px 8px';
export const textFieldBorderRadius = '4px 4px 0 0';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    compactSummaryList: true;
  }
}

const lightTheme = createTheme(
  {
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1024,
        lg: 1280,
        xl: 1920,
        compactSummaryList: 600,
      },
    },
    palette: {
      mode: 'light',
      primary: {
        main: '#42504e', // Forefront green
        dark: '#7e7c83', //Forefront grey
        contrastText: '#7D5365', // Forefront pink
      },
      secondary: {
        main: '#111', // dark grey
        dark: '#756256', // Forefront brown
        contrastText: '#555977', // Forefront purple
      },
      background: {
        default: '#fff',
        paper: '#f2f2f2',
      },
      text: {
        primary: '#1C1B1F',
      },
      info: {
        main: '#386794',
        light: '#b5cde3',
        dark: '#1f3951',
        contrastText: '#ecf3f8',
      },
      warning: {
        main: '#e4860c',
        light: '#f3ca95',
        dark: '#704206',
        contrastText: '#fbefdf',
      },
      error: {
        main: '#c20a0a',
        light: '#f5a9a9',
        dark: '#910808',
        contrastText: '#fdecec',
      },
      success: {
        main: '#019853',
        light: '#98e6c2',
        dark: '#014c29',
        contrastText: '#ebfaf3',
      },
      grey: {
        100: '#f0f0f0',
        200: '#F6F6F6',
        300: '#dedede',
        400: '#c4c4c4',
        500: '#F4F6F7',
        600: '#7a7a7a',
        700: '#E3E3E7',
        800: '#333333',
      },
      divider: '#e5e5e5',
    },
    shape: {
      borderRadius: 16,
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            '.MuiInputBase-root': {
              borderRadius: textFieldBorderRadius,
              '&:after': {
                borderBottom: '1px solid #ff600b',
                borderImageSlice: 1,
                borderImageSource:
                  'linear-gradient(to right, #ff600b, #ffb7f8, #79f2fb)',
              },
              '&:before': {
                borderBottom: '1px solid #dedede',
              },
              '&.Mui-error:before': {
                borderBottomColor: '#e4860c',
              },
              '&.Mui-focused, &:hover': {
                backgroundColor: 'white',
                borderColor: 'transparent',
                '&:not(.Mui-disabled, .Mui-error):before': {
                  borderBottom: '1px solid #c4c4c4',
                },
              },
              '&.Mui-disabled': {
                backgroundColor: '#dedede',
                color: '#333333',
                WebkitTextFillColor: '#333333',
              },
            },
            '.MuiInputBase-input': {
              padding: textFieldPadding,
              '&.Mui-disabled': {
                WebkitTextFillColor: '#333333',
              },
            },
            '.MuiFormHelperText-root': {
              marginLeft: '8px',
              '&.Mui-error': {
                color: '#704206',
              },
            },
            '.MuiFormLabel-root': {
              opacity: 1,
              color: '#7a7a7a',
              '&.Mui-error': {
                color: '#7a7a7a',
              },
            },
            '.MuiFormLabel-asterisk.Mui-error': {
              color: 'inherit',
            },
            '&:not(.Mui-disabled):hover::before': {
              borderColor: '#c4c4c4',
            },
            '&.MuiFilledInput-root:after': {
              borderImageSource:
                'linear-gradient(to right, #ff600b, #ffb7f8, #79f2fb)',
            },
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: '#555977',
            '&:hover': {
              color: '#1C1B1F',
            },
          },
        },
      },
    },
    typography: {
      fontFamily: ['Manrope', 'sans-serif'].join(','),
      h1: {
        fontSize: '1.94rem',
        fontWeight: 500,
        '@media (min-width:600px)': {
          fontSize: '1.94rem',
        },
        '@media (min-width:840px)': {
          fontSize: '1.94rem',
        },
      },
      h2: {
        fontSize: '1.25rem',
        fontWeight: 500,
        '@media (min-width:600px)': {
          fontSize: '1.25rem',
        },
        '@media (min-width:840px)': {
          fontSize: '1.25rem',
        },
      },
      h3: {
        fontSize: '1rem',
        fontWeight: 400,
        '@media (min-width:600px)': {
          fontSize: '1rem',
        },
        '@media (min-width:840px)': {
          fontSize: '1rem',
        },
      },
      h4: {
        fontSize: '1rem',
        '@media (min-width:600px)': {
          fontSize: '1rem',
        },
        '@media (min-width:840px)': {
          fontSize: '1rem',
        },
      },
      h5: {
        fontSize: '1rem',
        '@media (min-width:600px)': {
          fontSize: '1rem',
        },
        '@media (min-width:840px)': {
          fontSize: '1rem',
        },
      },
      h6: {
        fontSize: '1rem',
        '@media (min-width:600px)': {
          fontSize: '1rem',
        },
        '@media (min-width:840px)': {
          fontSize: '1rem',
        },
      },
      body1: {
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: 1.5,
        letterSpacing: '0.01em',
      },
      body2: {
        color: '#333',
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: 1.2,
      },
      button: {
        textTransform: 'capitalize',
        fontWeight: 700,
        letterSpacing: '0.02em',
      },
    },
  },
  svSE,
  coreSvSE,
);

export { lightTheme };
