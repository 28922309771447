import { MaterialSymbol } from '@components/MaterialSymbol';
import { ADMIN_ROLE, DEV_ROLE, USER_ROLE } from '@components/Role/Roles';
import { UserRole } from '@enums/Enums';
import { lazy } from 'react';

const StartPage = lazy(() => import('@components/Pages/StartPage'));
const SupportPage = lazy(() => import('@components/Pages/SupportPage'));
const PlaygroundPage = lazy(() => import('@components/Pages/PlaygroundPage'));
const StartPageAdmin = lazy(() => import('@components/Pages/StartPageAdmin'));
const OnboardingPage = lazy(() => import('@components/Pages/OnboardingPage'));
export interface Route {
  path: string;
  name: string;
  icon: React.ReactNode;
  iconFilled?: React.ReactNode;
  element: React.ReactNode;
  roles: UserRole[];
  showInNavigation?: boolean;
}

export const routes: Route[] = [
  {
    name: 'Konsulter',
    path: '/',
    icon: <MaterialSymbol icon="breaking_news" fill={false} />,
    iconFilled: <MaterialSymbol icon="breaking_news" fill={true} />,
    element: <StartPage />,
    roles: USER_ROLE,
  },
  {
    name: 'Konsulter',
    path: '/admin',
    icon: <MaterialSymbol icon="breaking_news" fill={false} />,
    iconFilled: <MaterialSymbol icon="breaking_news" fill={true} />,
    element: <StartPageAdmin />,
    roles: ADMIN_ROLE,
  },
  {
    name: 'Nyanställd',
    path: '/onboarding',
    icon: <MaterialSymbol icon="group_add" fill={false} />,
    iconFilled: <MaterialSymbol icon="group_add" fill={true} />,
    element: <OnboardingPage />,
    roles: [...ADMIN_ROLE, ...USER_ROLE],
  },
  {
    name: 'Support',
    path: '/support',
    icon: <MaterialSymbol icon="help" fill={false} />,
    iconFilled: <MaterialSymbol icon="help" fill={true} />,
    element: <SupportPage />,
    roles: [...ADMIN_ROLE, ...USER_ROLE],
  },
  {
    name: 'Playground',
    path: '/playground',
    icon: <MaterialSymbol icon="person" fill={false} />,
    iconFilled: <MaterialSymbol icon="person" fill={true} />,
    element: <PlaygroundPage />,
    roles: DEV_ROLE,
  },
];
