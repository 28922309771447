import { styled } from '@mui/material/styles';

const PageLoadingSpinnerContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '50vh',
}));

export default PageLoadingSpinnerContainer;
