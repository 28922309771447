import { RoleSwitcher } from './RoleSwitcher/RoleSwitcher';
import { StyledBox } from './styles';

export const DeveloperSettings = () => {
  return (
    <StyledBox>
      <RoleSwitcher />
    </StyledBox>
  );
};
