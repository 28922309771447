import { Box, styled } from '@mui/material';

export const StyledBox = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  right: 0,
  backgroundColor: '#eee',
  padding: '0.5rem 1rem',
  borderTopLeftRadius: '1rem',
  transform: 'translateY(100%)',
  transition: 'all 0.3s ease-in-out',

  display: 'flex',
  flexFlow: 'row wrap',
  gap: theme.spacing(2),
  maxWidth: '100%',

  '& > *': {
    width: '200px',
  },

  '&:hover': {
    transform: 'translateY(0)',
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '-20px',
    right: 0,
    width: '20px',
    height: '20px',
    backgroundColor: '#eee',
    borderTopLeftRadius: '1rem',
    borderTopRightRadius: '1rem',
  },
}));
