import { useMsal } from '@azure/msal-react';
import { MaterialSymbol } from '@components/MaterialSymbol';
import {
  StyledListItemIcon,
  StyledMenuItem,
} from '@components/UserIconButton/styles';

export const LogoutItem: React.FC = () => {
  const { instance } = useMsal();
  const handleLogout = () => {
    sessionStorage.removeItem('authorization-token');
    instance.logoutRedirect();
  };
  return (
    <StyledMenuItem
      title="Logga ut"
      onClick={() => handleLogout()}
      data-cy="logout-button"
      sx={{ padding: '8px 16px 16px' }}
    >
      <StyledListItemIcon>
        <MaterialSymbol icon="logout" size={20} />
      </StyledListItemIcon>
      Logga ut
    </StyledMenuItem>
  );
};
